#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .swiper-v {
    background: #eee;
  }
  
  .activeGalleryButton {
    background-color: #292929;
    color: #a70000;
  }

  .swiper-button-next, .swiper-button-prev {
    color: #a70000;
  }
  .merriweather-light {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .merriweather-light-italic {
    font-family: "Merriweather", serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .merriweather-regular {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .merriweather-regular-italic {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .merriweather-bold-italic {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .merriweather-black {
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .merriweather-black-italic {
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: italic;
  }
  